@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  max-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --max-page-size: 1200px;
  --text-primary: black;
  --text-secondary: #ee6926;
  --text-secondary--dark: #cb4f11;
}

body {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

a {
  color: #ee6926;
  color: var(--text-secondary);
  text-decoration: none;
  cursor: pointer;
  transition: color 0.1s;
}
a:hover {
  color: #cb4f11;
  color: var(--text-secondary--dark);
}

.App-page {
  width: 100%;
  max-width: 1200px;
  max-width: var(--max-page-size);
  padding: 0 2rem;
  margin-left: auto;
  margin-right: auto;
}

h3 {
  font-size: 1rem;
}

.Header-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.Header-logo {
  flex-shrink: 1;
}
.Header-logo img {
  width: 100%;
}

.Header-linkContainer {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 1rem;

  flex-grow: 1;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.Header-link {
  margin: 0 1rem;
  list-style-type: none;
  font-weight: 500;
  font-size: 1.1rem;
}
.Header-link a {
  color: var(--text-primary);
}
.Header-link a:hover {
  color: var(--text-secondary);
}

.Header-hamburger {
  display: none;
}
.Header-close {
  display: none;
}

@media only screen and (max-width: 600px) {
  .Header-hamburger {
    display: block;
    min-width: 36px;

    position: fixed;
    top: 20px;
    left: 20px;

    cursor: pointer;
  }
  .Header-close {
    display: block;
  }

  .Header-linkContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: white;
    display: none;
    z-index: 9;
    margin: 0;
  }
  .Header-linkContainer.show {
    display: block;
  }

  .Header-link {
    text-align: center;
    font-size: 2rem;
    margin: 3rem;
  }
  .Header-close {
    text-align: right;
    cursor: pointer;
  }
}

.Gallery-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.Gallery-single {
  width: 100%;
  position: relative;
}

.Gallery-image {
  width: 100%;
}

.Gallery-description {
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;

  background: rgba(255, 255, 255, 0.4);
  opacity: 0;
  transition: opacity 0.2s;
}
.Gallery-single:hover .Gallery-description {
  opacity: 1;
}

@media only screen and (max-width: 1200px) {
  .Gallery-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 900px) {
  .Gallery-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 600px) {
  .Gallery-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

