.Gallery-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.Gallery-single {
  width: 100%;
  position: relative;
}

.Gallery-image {
  width: 100%;
}

.Gallery-description {
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;

  background: rgba(255, 255, 255, 0.4);
  opacity: 0;
  transition: opacity 0.2s;
}
.Gallery-single:hover .Gallery-description {
  opacity: 1;
}

@media only screen and (max-width: 1200px) {
  .Gallery-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 900px) {
  .Gallery-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 600px) {
  .Gallery-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
