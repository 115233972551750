.Header-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.Header-logo {
  flex-shrink: 1;
}
.Header-logo img {
  width: 100%;
}

.Header-linkContainer {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0 1rem;

  flex-grow: 1;
  min-width: max-content;
}

.Header-link {
  margin: 0 1rem;
  list-style-type: none;
  font-weight: 500;
  font-size: 1.1rem;
}
.Header-link a {
  color: var(--text-primary);
}
.Header-link a:hover {
  color: var(--text-secondary);
}

.Header-hamburger {
  display: none;
}
.Header-close {
  display: none;
}

@media only screen and (max-width: 600px) {
  .Header-hamburger {
    display: block;
    min-width: 36px;

    position: fixed;
    top: 20px;
    left: 20px;

    cursor: pointer;
  }
  .Header-close {
    display: block;
  }

  .Header-linkContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: white;
    display: none;
    z-index: 9;
    margin: 0;
  }
  .Header-linkContainer.show {
    display: block;
  }

  .Header-link {
    text-align: center;
    font-size: 2rem;
    margin: 3rem;
  }
  .Header-close {
    text-align: right;
    cursor: pointer;
  }
}
