@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,400&display=swap");

:root {
  --max-page-size: 1200px;
  --text-primary: black;
  --text-secondary: #ee6926;
  --text-secondary--dark: #cb4f11;
}

body {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

a {
  color: var(--text-secondary);
  text-decoration: none;
  cursor: pointer;
  transition: color 0.1s;
}
a:hover {
  color: var(--text-secondary--dark);
}

.App-page {
  width: 100%;
  max-width: var(--max-page-size);
  padding: 0 2rem;
  margin-left: auto;
  margin-right: auto;
}

h3 {
  font-size: 1rem;
}
